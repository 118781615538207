.theo-primary-color,
.vjs-selected {
    color: #F6E107 !important;
}

.theo-primary-background {
    color: #000000 !important;
    background-color: #F6E107 !important;
}

.theo-secondary-color {
    color: #ffffff !important;
}

.theo-secondary-background {
    color: #000000 !important;
    background-color: #ffffff !important;
}

.theo-tertiary-color {
    color: #000000 !important;
}

.theo-tertiary-background {
    color: #ffffff !important;
    background-color: #000000 !important;
}

/* HIDE SETTINGS BUTTON */
.theoplayer-skin .theo-settings-control-button {
  display: none;
}
.theo-quality-label {
  display: none;
}

/* HIDE BIG PLAY BUTTON */
.theoplayer-skin .vjs-big-play-button {
  color: rgba(0, 0, 0, 0.4)!important;
  background: #34277e!important;
  box-shadow: inset -5px 5px 3px #5a4f97!important;
  width: 4em;
  height: 4em;
  padding: 0;
}
.theoplayer-skin:hover .vjs-big-play-button {
  opacity: 0.75;
}
.theoplayer-skin .vjs-big-play-button .theo-big-play-button-svg-container svg {
  display: none;
}
.theoplayer-skin .vjs-big-play-button .theo-big-play-button-svg-container {
  position: relative;
}
.theoplayer-skin .vjs-big-play-button .theo-big-play-button-svg-container:before {
  content: ' ';
  position: absolute;
  left: 1.35em;
  top: -1em;
  border: 1em solid transparent;
  border-left: 1.5em solid white;
}
/* .theoplayer-skin:hover .vjs-big-play-button, .theoplayer-skin .vjs-big-play-button:focus {
  background: none;
}
.theoplayer-skin .vjs-big-play-button:before {
  content: "";
  background: none;
  display: none;
}
.theo-big-play-button-svg-container svg {
  display: none;
}
.theoplayer-skin:hover .vjs-big-play-button:after,
.theoplayer-skin .vjs-big-play-button:focus:after {
  opacity: 0;
} */


/* BIGGER BUTTONS */
.theoplayer-skin .vjs-control-bar {
  height: 6em;
}
.theoplayer-skin .vjs-time-control {
  height: auto;
  align-self: center;
}
.theoplayer-skin .vjs-control-bar .vjs-control.vjs-button {
  width: 3em;
  height: 3em;
  background: #34277e;
  border-radius: 1.5em;
  margin: 0.2em;
  font-size: 1.5em;
  box-shadow: inset -1px 1px 3px #5a4f97;
}

/* REORDER ITEMS */
.vjs-current-time.vjs-time-control {
  order: -1;
  margin-left: 1em;
  margin-right: 0.2em;
}
.vjs-time-control.vjs-time-divider {
  display: none;
}
.vjs-duration.vjs-time-control.vjs-control {
  margin-right: 1em;
  margin-left: 0.2em;
}


/* MENU Z-INDEX FIX */
.theoplayer-skin .theo-menu {
  bottom: 6em;
}
.theoplayer-skin .theo-menu.theo-menu-animatable .theo-menu-animation-container {
  z-index: 2;
}

/* VOLUME BAR TOUCH AREA */
.theoplayer-skin .vjs-volume-control .vjs-volume-bar {
  position: relative;
  height: 0.6em;
  border-radius: 0.3em;
}
.vjs-slider-horizontal .vjs-volume-level {
  height: 0.6em;
  border-radius: 0.3em;
}
.video-js .vjs-volume-level:before {
  font-size: 1.4em;
}
.theoplayer-skin .vjs-volume-control .vjs-volume-bar:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  top: -2em;
  bottom: -2em;
}


/* CUSTOM OVERLAY OUTSIDE THEOPLAYER */
.theoplayer-wrapper .studio100-overlay {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 10px 12px;

  transition: opacity 0.3s;
  opacity: 0;

  font-weight: 600;
  font-family: sans-serif;

  color: #eeeeee;
}

.theoplayer-wrapper .studio100-classifications {
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
  border-bottom-right-radius: 28px;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.4);
}



/*
theo-seekbar-inside-controls

.theoplayer-skin .vjs-control-bar .vjs-control.vjs-button {
  width: 3em;
  height: 3em;
  background: #34277e;
  border-radius: 1.5em;
  margin: 0.2em;
  font-size: 1.5em;
  box-shadow: inset -1px 1px 3px #5a4f97;
}

*/

/* reposition items */
/* .vjs-control-bar {
  display: flex;
  align-items: center;
}
.vjs-progress-control {
  position: static;
} */
